import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { GlobalState } from "../../GlobalState";
import { DashboardSvg } from "../../assets/svg";
import DashboardSensorBoxV2 from "../sensor/DashboadSensorBoxV2";
import PumpStatusV2 from "../sensor/PumpStatusV2";
import PumpStatusV3 from "../sensor/PumpStatusV3";
import SolenoidBoxComponent from "../sensor/SolenoidBox";
import Banner from "../utils/banner";
import Loading from "../utils/loading";
// import InitNotifcation from "../notification/Init";
// import { HiOutlineRefresh } from "react-icons/hi"

const Container = styled.div`
  margin: auto;
  width: 90%;
`;

const ContentContainer = styled.div`
  // display:flex;
  // flex-direction: column;
`
const SensorType = styled.h3`
  color: #6a6a6a;
  margin-bottom: 0.7rem;
`;

// const RefreshWrapper = styled.span`
// display: flex;
// justify-content:flex-end;
// cursor:pointer;
// &:hover{
//   color:var(--primary);
// }

// `;

const SensorWrapper = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
  margin: 1rem 0;
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #fff;
  justify-content: center;
  padding: 0.2rem;
  margin: 0 auto;
  @media (min-width: 61.313em) {
    flex-direction: row;
  max-width: 85%;

  }
  `
const SensorReadingContainer = styled.div`
  border-radius: 20px;
  margin: 0 auto;
  flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    margin: 0.5rem auto;
    @media (min-width: 61.313em) {
      flex-direction: row;
  }
`;

const ContainerWrapper = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  `;

export default function DashboardV2() {
  const state = useContext(GlobalState);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    async function fetchData(token) {
      try {
        const sensor_list = await axios.get(`${process.env.REACT_APP_API}/user/v2/sensor_list`, {
          headers: { Authorization: token[0] },
        });
        if (sensor_list) {
          setList(sensor_list.data);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData(state.token);
  }, [state.token]);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );


  return (
    <Container>
      <Banner
        title={`Welcome ${state.userAPI.name}`}
        subtitle=""
      >
        <DashboardSvg />
      </Banner>

      <ContentContainer>

        {
          list.map((items, idx) => {
            if (items.sensors.length === 0) return null;
            return (
              <ContainerWrapper>
                <SensorType>{items.label}</SensorType>
                <SensorWrapper >
                  {
                    items.sensors.map((sensor, idx) => {
                      if (sensor?.motor_id && !sensor?.isBorewell) {
                        return (
                          <PumpStatusV2
                            motor_id={sensor.motor_id}
                            sensor_id={sensor.sensor_id}
                            isBorewell={sensor.isBorewell}
                          />
                        )
                      }

                      if (sensor?.motor_id && sensor?.isBorewell) {
                        return (
                          <PumpStatusV3
                            motor_id={sensor.motor_id}
                            sensor_id={sensor.sensor_id}
                            isBorewell={sensor.isBorewell}
                          />
                        )
                      }

                      if (sensor?.type === 'Solenoid Valve') {
                        return (
                          <SolenoidBoxComponent sensor={sensor} />
                        )
                      }

                      return (
                        <DashboardSensorBoxV2
                          key={idx}
                          sensor_id={sensor.sensor_id}
                          type={sensor.type} />
                      )
                    })
                  }
                </SensorWrapper>
              </ContainerWrapper>
            )
          })}

      </ContentContainer>

    </Container>
  );
}
