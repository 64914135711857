import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { RiSignalWifi3Fill, RiSignalWifiOffLine } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import styled, { css, keyframes } from "styled-components";
import { GlobalState } from "../../GlobalState";
import ToggleButton from "../utils/ToggleButton";
import Button from "../utils/RadioButton.js";
import Tooltip from "../utils/Tooltip";
import { editAccess, formatDate } from "../utils/utils";
import { MdUpdate } from "react-icons/md";
import AutoManualToggle from "../utils/DashboardToggle.js";
const PumpImg = styled.img`
  width: 50px;
  height: 100%;
  max-height: 50px;
  object-fit: contain;
`;

const BorewellPumpImg = styled.img`
  width: 30px;
  height: 100%;
  max-height: 50px;
  margin-bottom: 0.5rem;
`;

const RippleAnimation = keyframes`
  0% {box-shadow: 0 0 0px 0px rgba(101, 255, 120, 0.5);}
  100% {box-shadow: 0 0 0px 30px rgba(101, 255, 120, 0);}
`;

const PumpLight = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => (props.pumpState ? `#00e8a3` : `#d9594c`)};
  ${(props) =>
    props.pumpState &&
    css`
       {
        animation: ${RippleAnimation} 0.7s linear infinite;
      }
    `}
`;

const PumpStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0.5rem; */
`;

const Container = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem;
  position: relative;

  border: 2px solid #fff;
  background-color: #fff;
  &:hover {
    border: 2px solid var(--primary);
  }
  min-width: 260px;
  margin: 0.3rem;
`;

const SensorStatusIndicator = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
`;

const WaterSenseLight = styled.div`
  width: 25px;
  height: 12px;
  border-radius: 20%;
  background-color: ${(props) =>
    props.pumpState === 1 ? `#1AA3EC` : `#d9594c`};
  ${(props) =>
    props.pumpState === 1 &&
    css`
       {
        animation: ${RippleAnimation} 0.7s linear infinite;
      }
    `}
`;

// const TimeElapsed = styled.p`
//   font-size: 0.7rem;
//   font-weight:bold;

// `;

const TimeElapsed = styled.p`
  // margin: 0.5rem;
  font-size: 0.7rem;
  font-weight: bold;
  text-align: end;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @media (min-width: 61.313em) {
    text-align: end;
  }
`;
const LocationText = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin: 0.1rem 0;
  @media (min-width: 61.313em) {
    text-align: end;
  }
`;

const ToggleWrapper = styled.div`
  margin: 0 auto;
`;

const LocationName = styled.h5`
  word-wrap: break-word;
  max-width: 90%;
`;

const DummyWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 0%;
  background-color: transparent;
  width: 60%;
  height: 100%;
`;

const WaterFlowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  // align-items: center;
  justify-content: space-between;
`;

export default function PumpStatusV3({ sensor_id, motor_id, isBorewell }) {
  const history = useHistory();

  const location = useLocation();
  const isDetailPage = location.pathname.includes("/motor/");
  const state = useContext(GlobalState);
  const [list, setList] = useState([]);
  const [pumpState, setPumpState] = useState(false);
  const [manualMode, setManualMode] = useState(false);
  const [pumpData, setPumpData] = useState({});
  const [sensorStatus, setSensorStatus] = useState("online");
  const [pumpExist, setPumpExist] = useState(true);

  const locationName = !pumpData?.motor_status?.location_name
    ? "Borewell"
    : pumpData?.motor_status?.location_name;

  async function togglePumpStatus(motor_id) {
    try {
      setPumpState(!pumpState);

      const pumpStatus = await axios.get(
        `${process.env.REACT_APP_API}/sensor/v2/toggle_pump_status?motor_id=${motor_id}`,
        {
          headers: { Authorization: state.token[0] },
        }
      );

      // setPumpState(pumpStatus.data)
    } catch (err) {
      console.log(err.response.data.message);
    }
  }

  async function toggleMode(motor_id) {
    try {
      const pumpStatus = await axios.get(
        `${process.env.REACT_APP_API}/sensor/borewell/toggle-operation/${motor_id}`,
        {
          headers: { Authorization: state.token[0] },
        }
      );
      setManualMode(!manualMode);
    } catch (err) {
      console.log(err.response.data.message);
    }
  }

  async function fetchData(token, motor_id, sensor_id, type) {
    try {
      const pumpStatus = await axios.get(
        `${process.env.REACT_APP_API}/sensor/v2/pump_status?motor_id=${motor_id}`,
        {
          headers: { Authorization: token[0] },
        }
      );
      setPumpData(pumpStatus.data);
      setPumpState(parseInt(pumpStatus.data.motor_status.motor_on));
      setSensorStatus(pumpStatus.data.sensor_status);
      setManualMode(Boolean(pumpStatus.data.motor_status?.manual_mode));

      const sensorReading = await axios.get(
        `${process.env.REACT_APP_API}/sensor/reading?sensor_id=${sensor_id}&type=${type}`,
        {
          headers: { Authorization: token[0] },
        }
      );

      if (sensorReading) {
        setList(sensorReading.data);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setPumpExist(false);
      }

      console.log(err.response.data.message);
    }
  }

  // const [isAutoMode, setIsAutoMode] = useState(() => {
  //   return localStorage.getItem(`isAutoMode_${motor_id}`) === "true";
  // });

  // useEffect(() => {
  //   if (!motor_id) return;

  //   const fetchMode = async () => {
  //     try {
  //       const apiUrl = `${process.env.REACT_APP_API}/stp/bore/get-operation/${motor_id}`;
  //       const response = await axios.get(apiUrl, {
  //         headers: { Authorization: state.token[0] },
  //       });

  //       if (response.data?.manual_mode !== undefined) {
  //         const fetchedMode = !response.data.manual_mode;
  //         setIsAutoMode(fetchedMode);
  //         localStorage.setItem(`isAutoMode_${motor_id}`, fetchedMode.toString());
  //       }
  //     } catch (err) {
  //       console.error("Error fetching mode:", err.response?.data?.message || err.message);
  //     }
  //   };

  //   fetchMode();
  // }, [motor_id]);

  useEffect(() => {
    fetchData(state.token, motor_id, sensor_id, "water-sense");
    const interval = setInterval(() => {
      fetchData(state.token, motor_id, sensor_id, "water-sense");
    }, 5000);

    return () => clearInterval(interval);
  }, [state.token, motor_id, sensor_id]);

  if (!pumpExist) return null;

  return (
    <Container
    // onClick={
    //     (e) => {
    //         if (!isBorewell) return
    //         // /motor/:motor_id/sensor/:sensor_id/:sensor_type
    //         history.push(`/motor/${motor_id}/sensor/${sensor_id}/Pump - ${locationName}`)
    //     }
    // }
    >
      {/* <DummyWrapper
                className="test"
                onClick={
                    (e) => {
                        if (!isBorewell) return
                        // /motor/:motor_id/sensor/:sensor_id/:sensor_type
                        history.push(`/motor/${motor_id}/sensor/${sensor_id}/Pump - ${locationName}`)
                    }
                }
            >

            </DummyWrapper> */}
      {/* <SensorStatusIndicator>
        <div style={{ display: "flex" }}>
          {sensorStatus === "online" ? (
            <RiSignalWifi3Fill size={20} color={"green"} />
          ) : (
            <Tooltip
              content={<>Motor is offline.</>}
              direction="right"
              delay="0"
            >
              <RiSignalWifiOffLine size={20} color={"red"} />
            </Tooltip>
          )}
          &nbsp;
          <LocationName
            onClick={(e) => {
              if (!isBorewell) return;
              // /motor/:motor_id/sensor/:sensor_id/:sensor_type
              history.push(
                `/motor/${motor_id}/sensor/${sensor_id}/Pump - ${locationName}`
              );
            }}
          >
            {locationName}
          </LocationName>

          <ToggleButton
          id={`autoManualToggle-${motor_id}`}
          checked={isAutoMode}
          onChange={setAutoMode}
          optionLabels={["Auto", "Manual"]}
          small
        />
        </div>
      </SensorStatusIndicator> */}

      <SensorStatusIndicator>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            {sensorStatus === "online" ? (
              <RiSignalWifi3Fill size={20} color={"green"} />
            ) : (
              <Tooltip
                content={<>Motor is offline.</>}
                direction="right"
                delay="0"
              >
                <RiSignalWifiOffLine size={20} color={"red"} />
              </Tooltip>
            )}{" "}
            <LocationName
              onClick={(e) => {
                if (!isBorewell) return;
                // /motor/:motor_id/sensor/:sensor_id/:sensor_type
                history.push(
                  `/motor/${motor_id}/sensor/${sensor_id}/Pump - ${locationName}`
                );
              }}
            >
              {locationName}
            </LocationName>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.1rem",
              }}
            >
              <Button
                id={`${motor_id}-manual`}
                checked={!manualMode}
                onChange={() => toggleMode(motor_id)}
                optionLabels={["Auto", "Manual"]}
                small
              />
              <p
                style={{
                  margin: 0,
                  fontSize: "0.6rem",
                  color: "#000000",
                  fontWeight: "bold",
                  width: "40px",
                  textAlign: "center",
                }}
              >
                {manualMode ? "Manual" : "Auto"}
              </p>
            </div>
          </div>
        </div>
      </SensorStatusIndicator>

      <SensorStatusIndicator>
        {/* <div style={{ display: 'flex' }}>
                    &nbsp;
                </div> */}
      </SensorStatusIndicator>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      > */}


      
      <WaterFlowWrapper>
        {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
               gap: "5rem",
            }}
          > */}

        <TimeElapsed>
          <div style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}>
            {" "}
            <MdUpdate /> &bull;{" "}
            {formatDate(pumpData?.motor_status?.updatedAt?.split(" IST")[0])}
          </div>

          {!isDetailPage && (
            <ToggleButton
              disabled={!editAccess(state.userAPI)}
              id={motor_id}
              checked={Boolean(pumpState)}
              onChange={() => togglePumpStatus(motor_id)}
            />
          )}
        </TimeElapsed>

        {/* </div> */}

        {/* <MdUpdate /> &bull; {formatDate(pumpData?.motor_status?.updatedAt?.split(" IST")[0])} */}
      </WaterFlowWrapper>
      {/* {list?.name === "water-sense" && (
  <div
    style={{
      backgroundColor: "#fafafa",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      padding: "12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between", // Ensures they are spaced apart
      width: "100%",
    }}
  >
    
    {list?.name === "water-sense" && (
      <WaterSenseLight pumpState={parseInt(list?.value ?? 0)} />
    )}
    

    <WaterFlowWrapper
      onClick={() => {
        history.push(`/sensor/${sensor_id}/Water%20Sense`);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
<div
  style={{
    display: "flex",
    alignItems: "center",
    gap: "0.3rem", // Ensures they stay close but not touching
  }}
>
  {list.sensor_status === "online" ? (
    <RiSignalWifi3Fill size={20} color={"green"} />
  ) : (
    <Tooltip content={<>Sensor is offline.</>} direction="right" delay="0">
      <RiSignalWifiOffLine size={20} color={"red"} />
    </Tooltip>
  )}

  <TimeElapsed>
    {list.date} &bull; {list.time}
  </TimeElapsed>
</div>

    </WaterFlowWrapper>

    {/* <div>
    {list?.name === "water-sense" && (
      <WaterSenseLight pumpState={parseInt(list?.value ?? 0)} />
    )}
    </div> */}
  {/* </div>
)} */} 



{list?.name === "water-sense" && (
  <div
    style={{
      backgroundColor: "#f0f0f0",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      padding: "12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between", 
      width: "100%",
      gap: "1rem",
      marginTop:"1rem" ,
    }}
  >
    
    <WaterFlowWrapper
      onClick={() => {
        history.push(`/sensor/${sensor_id}/Water%20Sense`);
      }}
      style={{
        display: "flex",
        flex: 1, 
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.3rem", 
        }}
      >
        {list.sensor_status === "online" ? (
          <RiSignalWifi3Fill size={20} color={"green"} />
        ) : (
          <Tooltip content={<>Sensor is offline.</>} direction="right" delay="0">
            <RiSignalWifiOffLine size={20} color={"red"} />
          </Tooltip>
        )}

        <TimeElapsed>
          {list.date} &bull; {list.time}
        </TimeElapsed>
      </div>
    </WaterFlowWrapper>

    {list?.name === "water-sense" && (
    <WaterSenseLight pumpState={parseInt(list?.value ?? 0)} />
    )}
  </div>
)}




     
     {/* <WaterFlowWrapper
  onClick={() => {
    history.push(`/sensor/${sensor_id}/Water%20Sense`);
  }}
>
  <TimeElapsed>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.3rem",
        marginTop: "0.5rem",
      }}
    >
      {list.sensor_status === "online" ? (
        <RiSignalWifi3Fill size={20} color={"green"} />
      ) : (
        <Tooltip content={<>Sensor is offline.</>} direction="right" delay="0">
          <RiSignalWifiOffLine size={20} color={"red"} />
        </Tooltip>
      )}

      <TimeElapsed>
        {list.date} &bull; {list.time}
      </TimeElapsed>
    </div>

    {list?.name === "water-sense" && (
      <div
        style={{
          transform: "translateX(-10px)", // Moves it 10px to the left
          display: "inline-block", // Ensures it respects positioning
        }}
      >
        <WaterSenseLight pumpState={parseInt(list?.value ?? 0)} />
      </div>
    )}
  </TimeElapsed>
</WaterFlowWrapper> */}


      {/* </div> */}
    </Container>
  );
}
