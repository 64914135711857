import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./auth/Login";
import Dashboard from "./dashboard/Dashboard";
import Report from "./report/Report";
import Profile from "./profile/Profile";
import NotFound from "./utils/not_found";
import Header from "./header/Header";
import SensorData from "./sensor/sensorData";
import Footer from "./Footer/Footer";
import { GlobalState } from "../GlobalState";
import styled from "styled-components";
import MotorData from "./sensor/motorData";
import DashboardV2 from "./dashboard/DashboardV2";
import SolenoidDetailPage from "./sensor/solenoidDetail";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledFooter = styled(Footer)`
  margin-top: auto;
`;


export default function Pages() {



  // context api 
  const state = useContext(GlobalState);
  // check if user is logged in
  const [isLogged] = state.userAPI.isLogged;

  if (isLogged) {
    // if user is logged in, render dashboard
    return <Container>
      <Header />
      <Switch>

        <Route path="/sensor/:sensor_id/:sensor_type" exact component={SensorData} />
        <Route path="/motor/:motor_id/sensor/:sensor_id/:sensor_type" exact component={MotorData} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/report" exact component={Report} />
        <Route path="/" exact component={DashboardV2} />
        {/* <Route 
          path="/solenoid/:solenoid_id/motor/:motor_id/overhead-tank/:sensor_id/:location_name" 
          component={SolenoidDetailPage}
        /> */}
         <Route 
          path="/solenoid/:solenoid_id/:location_name" 
          component={SolenoidDetailPage}
        />

        <Route path="*" exact component={NotFound} />
       

      </Switch>
      <StyledFooter />
    </Container>
  }

  // if user is not logged in, render login page
  return <Switch>
    <Route path="/" exact component={Login} />
    <Route path="*" component={NotFound} />
  </Switch>




}
