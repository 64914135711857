
// window.flutter = {
//   callHandler: window?.flutter_inappwebview?.callHandler
// }

const NativeHelper = {
  blobToBase64: (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  },
  NativeAskStoragePermission: () => {
    if (window && window?.flutter_inappwebview) {
      // window.flutter_inappwebview.askStoragePermission?.();
      window.flutter_inappwebview.callHandler('askStoragePermission');
    } else if (window && window["Native"]) {
      window["Native"].askStoragePermission?.();
    }
  },
  NativeDialPhoneNumber: (number) => {
    if (window && window?.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('dialPhoneNumber', number);
    } else if (window && window["Native"]) {
      window["Native"].dialPhoneNumber(number);
    }
  },
  NativeLocationPermission: () => {
    if (window && window["Android"]) {
      window["Android"].askForLocationPermission();
    } else if (window && window["Native"]) {
      window["Native"].askForLocationPermission();
    }
  },
  NativeDownloadFileWithUrl: (fileURL, fileName) => {
    if (window && window["Android"]) {
      window["Android"].downloadFileWithBase64?.(fileURL, fileName);
    } else if (window && window["Native"]) {
      window["Native"].downloadFileWithBase64?.(fileURL, fileName);
    } else {
      window.open(fileURL, "_blank");
    }
  },
  NativeDownloadFileWithBase64: async (blob, fileName) => {
    const fileURL = URL.createObjectURL(blob);
    const base64Data = await NativeHelper.blobToBase64(blob);
    console.log({ base64Data });
    if (window && window?.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('downloadFileWithBase64', base64Data, fileName);


      // if (window && window["Android"]) {
      //   window["Android"].downloadFileWithBase64?.(base64Data, fileName);
    } else if (window && window["Native"]) {
      window["Native"].downloadFileWithBase64?.(base64Data, fileName);
    } else {
      window.open(fileURL, "_blank");
    }
  },
  sendWhatsappMessage: (number, message) => {
    // if (window && window["flutter"]) {
    // window["flutter"].sendWhatsAppMessage?.(number, message);
    if (window && window?.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('sendWhatsappMessage', number, message);
    } else if (window && window["Native"]) {
      window["Native"].sendWhatsAppMessage?.(number, message);
    } else {
      window.open(`https://wa.me/${number}/?text=${message}`, "_blank");
    }
  },

  navigateToGoogleMap: (location) => {
    const locationUrl = `https://maps.google.com/maps?q=${location?.lat},${location?.lon}`;
    if (window && window["Android"]) {
      window["Android"].openMaps?.(locationUrl);
    } else if (window && window["Native"]) {
      window["Native"].openMaps?.(locationUrl);
    } else {
      if (window) {
        window.open(locationUrl, "_blank");
      }
    }
  },

  share: (data1) => {
    const url = ``;
    const data = {
      title: `H`,
      url: url,
      message: ``,
    };
    if (window && window["Android"]) {
      window["Android"].share?.(data);
    } else if (window && window["Native"]) {
      window["Native"].share?.(data);
    } else if (navigator.share) {
      navigator
        .share(data)
        .then((res) => { })
        .catch((e) => console.log(e));
    }
  },
  loginOneSignal: (userId) => {
    if (window && window?.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('loginOneSignal', userId);
    } else if (window && window["Native"]) {
      window["Native"].loginOneSignal?.(userId);
    }
  },
  logoutOneSignal: () => {
    if (window && window?.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('logoutOneSignal');
    } else if (window && window["Native"]) {
      window["Native"].logoutOneSignal?.();
    }
  },

};

export default NativeHelper;
