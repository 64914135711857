import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  HomeIcon,
  LogoutIcon,
  ProfileIcon,
  ReportIcon,
  UserManualIcon
} from "../../assets/icons/index";
import { Logo } from "../../assets/svg/index";
import NativeHelper from "../utils/nativeUtils";

const NavWrapper = styled.div`
  @media (min-width: 61.313em) {
    margin: 1rem 2rem;
    display: flex;

    justify-content: space-between;
  }
`;
const NavLinks = styled.ul`
  display: flex;
`;
const LogoWrapper = styled.div`
  width: 150px;
  height: auto;
`;
const LinkName = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

  &>p{
   visibility: hidden;
  }
  &:hover {
  fill: var(--primary);
    &>p{
      visibility: visible;
    }
  }
`;

const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    cursor: pointer;
    svg {
      fill: var(--primary);
    }
    ${LinkName} {
      visibility: visible;
    }
  }
`;


export default function HeaderDesktop() {
  const logoutUser = async () => {

    await axios.get(`${process.env.REACT_APP_API}/user/logout`, {
      withCredentials: true
    })
    NativeHelper.logoutOneSignal();
    // localStorage.removeItem("firstLogin");
    localStorage.clear()
    window.location.href = "/";
  };

  return (
    <NavWrapper>


      <Link to="/"> <LogoWrapper>
        <Logo />
      </LogoWrapper></Link>
      <NavLinks>

        <Link to="/">
          <LinkName>
            <IconWrapper>
              <HomeIcon />
            </IconWrapper>
            <p>
              Home
            </p>
          </LinkName>
        </Link>
        <Link to="/report">
          <LinkName>
            <IconWrapper>
              <ReportIcon />
            </IconWrapper>
            <p>
              Report
            </p>
          </LinkName>
        </Link>
        <Link to="/profile">
          <LinkName>
            <IconWrapper>
              <ProfileIcon />
            </IconWrapper>
            <p>
              Profile
            </p>
          </LinkName>
        </Link>
        <Link to="/User-Manual.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <LinkName>
            <IconWrapper>
              <UserManualIcon />
            </IconWrapper>
            <p>
              User Manual
            </p>
          </LinkName>
        </Link>
        <Link to="/" onClick={logoutUser}>
          <LinkName>
            <IconWrapper>
              <LogoutIcon />
            </IconWrapper>
            <p>
              Logout
            </p>
          </LinkName>
        </Link>
      </NavLinks>
    </NavWrapper>
  );
}
