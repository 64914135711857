import axios from "axios";
import { bool } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NativeHelper from "../utils/nativeUtils";


const StyledMenu = styled.nav`
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #effffa;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  a {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    margin: 1rem auto;
    letter-spacing: 0.3rem;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;
  }
`;
const LogoutBtn = styled(Link)`

color:red !important;
`

const Menu = ({ open, setOpen, ...props }) => {
  const logoutUser = async () => {
    await axios.get(`${process.env.REACT_APP_API}/user/logout`, {
      withCredentials: true
    })

    NativeHelper.logoutOneSignal();

    // localStorage.removeItem("firstLogin");
    localStorage.clear()
    window.location.href = "/";
  };
  return (
    <StyledMenu open={open} {...props}>
      <Link to="/" onClick={() => setOpen(!open)}>
        Dashboard
      </Link>
      <Link to="/report" onClick={() => setOpen(!open)}>
        Report
      </Link>
      <Link to="/profile" onClick={() => setOpen(!open)}>
        Profile
      </Link>

      <Link to="/User-Manual.pdf"
        target="_blank"
        rel="noreferrer"
      >
        User Manual
      </Link>

      <br />
      <br />


      <LogoutBtn to="/" onClick={logoutUser}>
        Logout
      </LogoutBtn>


    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;
