import axios from "axios";
import React, { useContext,useState } from "react";
import { MdUpdate } from "react-icons/md";
import { RiSignalWifi3Fill, RiSignalWifiOffLine } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { GlobalState } from "../../GlobalState.js";
import ToggleButton from "../utils/ToggleButton";
import Tooltip from "../utils/Tooltip.js";
import { editAccess, formatDate } from "../utils/utils.js";
import SolenoidDetailPage from "./solenoidDetail.js";


const Container = styled.div`
 box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem;
  position: relative;


  border: 2px solid #fff;
  background-color: #fff;
  &:hover {
    border: 2px solid var(--primary);
  }
  min-width: 260px;
  margin: 0.3rem;
`;

const LocationName = styled.h5`
  word-wrap: break-word;
  max-width: 90%;
`;

const TimeElapsed = styled.p`
  font-size: 0.7rem;
  font-weight: bold;
  text-align: end;
  display: flex;
  align-items: center;
  @media (min-width: 61.313em) {
    text-align: end;
  }
`;
const TopLine = styled.div`
display: flex;
align-items: center;
gap: 0.5rem;  
`;

const BottomLine = styled.div`
display: flex;
align-items: center;
gap: 0.5rem;
justify-content: space-between;
margin-top: 0.5rem;
`;



export default function SolenoidBoxComponent({ sensor }) {
  const history = useHistory();

  const location = useLocation();
  const state = useContext(GlobalState);
  const [solenoid, setSolenoid] = React.useState(sensor);
  const { solenoid_id, valve_on, sensor_online, updatedAt, motor_id, sensor_id} = solenoid || {};

  const [solenoidStatus, setSolenoidStatus] = React.useState(valve_on === '1');




  // const toggleSolenoid = async (id) => {
  //   try {
  //     const solenoidStatusResponse = await axios.patch(
  //       `${process.env.REACT_APP_API}/sensor/solenoid?solenoid_id=${id}`,
  //       {
  //         valve_on: !solenoidStatus ? '1' : '0',
  //       },
  //       {
  //         headers: { Authorization: state.token[0] },
  //       }
  //     );

  //     if (solenoidStatusResponse.data) {
  //       setSolenoidStatus(!solenoidStatus);
     
  //     }
  //   }
  //   catch (err) {
  //     console.log(err);
  //   }


  // }


  const toggleSolenoid = async (id) => {
    try {
      // Optimistically update the state
      setSolenoidStatus((prev) => !prev);
  
      // Send PATCH request to update solenoid status
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/sensor/solenoid?solenoid_id=${id}`,
        { valve_on: !solenoidStatus ? '1' : '0' },
        { headers: { Authorization: state.token[0] } }
      );
  
      if (response.data) {
        // Ensure state updates with latest backend response
        await fetchData(id);
      }
    } catch (err) {
      console.error("Error toggling solenoid:", err);
      // Revert optimistic update if API call fails
      setSolenoidStatus((prev) => !prev);
    }
  };
  
  
  

  // const fetchData = async (id) => {
  //   try {
  //     const solenoidStatusResponse = await axios.get(
  //       `${process.env.REACT_APP_API}/sensor/solenoid?solenoid_id=${id}`,
  //       {
  //         headers: { Authorization: state.token[0] },
  //       }
  //     );

  //     if (solenoidStatusResponse.data) {
  //       console.log("API Response:", solenoidStatusResponse.data.data);
  //     //   setSolenoid(solenoidStatusResponse.data.data)
  //     // }

  //     setSolenoid((prev) => ({
  //       ...prev,
  //       ...solenoidStatusResponse.data.data, 
  //     }));
  //   }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }


  const fetchData = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/sensor/solenoid?solenoid_id=${id}`,
        { headers: { Authorization: state.token[0] } }
      );
  
      if (response.data?.data) {
        console.log("API Response:", response.data.data);
  
        // Update entire state object
        setSolenoid(response.data.data);
        setSolenoidStatus(response.data.data.valve_on === '1'); // Sync UI state
      }
    } catch (err) {
      console.error("Error fetching solenoid data:", err);
    }
  };
  
  

  // React.useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchData(solenoid_id);
  //   }, 10000);

  //   return () => clearInterval(interval);
  // }, [solenoid_id]);

  React.useEffect(() => {
    if (solenoid_id) {
      fetchData(solenoid_id); // Fetch data when component mounts
  
      const interval = setInterval(() => {
        fetchData(solenoid_id);
      }, 5000);
  
      return () => clearInterval(interval);
    }
  }, [solenoid_id]);
  
  
  return (
    <>
      <Container>
      <LocationName
      onClick={async () => {
        try {
         // Ensure values are correctly passed and valid
          // if (!solenoid_id || !motor_id || !sensor_id) {
          //   console.error('Missing required params for navigation.');
          //   return;
          // }

          history.push(`/solenoid/${solenoid_id}/${sensor.location_name}`);
        } catch (error) {
          console.error('Error navigating:', error);
        }
      }}
    >
        <TopLine>
          {sensor_online ? (
            <RiSignalWifi3Fill size={20} color={"green"} />
          ) : (
            <Tooltip
              content={<>Motor is offline.</>}
              direction="right"
              delay="0"
            >
              <RiSignalWifiOffLine size={20} color={"red"} />
            </Tooltip>
          )}
          {/* <LocationName>
            {sensor.location_name}
          </LocationName> */}

            {/* <LocationName
          onClick={() =>
            history.push(`/solenoid/${solenoid_id}/motor/${motor_id}/overhead-tank/${sensor_id}/${sensor.location_name}`)
          }
        >
          {sensor.location_name}
        </LocationName> */}
      {sensor.location_name}
    


        </TopLine>
        <div style ={{alignItems:"center", justifyContent:"flex-end", display:"flex"}}>
        <ToggleButton
            disabled={!editAccess(state.userAPI)}
            id={solenoid_id}
            checked={solenoidStatus}
            onChange={() => toggleSolenoid(solenoid_id)}
          ></ToggleButton>
          </div>

        <BottomLine>
          <TimeElapsed>
            <MdUpdate /> &bull; &nbsp;
            {formatDate(updatedAt)}
          </TimeElapsed>
         
        </BottomLine>
        </LocationName>
      </Container >
    </>
  );
}
