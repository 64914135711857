import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { MdUpdate } from "react-icons/md";
import { RiSignalWifi3Fill, RiSignalWifiOffLine } from "react-icons/ri";
import styled from "styled-components";
import DashboadSensorBoxV2 from "./DashboadSensorBoxV2";
import { GlobalState } from "../../GlobalState.js";
import Tooltip from "../utils/Tooltip.js";
import PumpStatusV2 from "./PumpStatusV2";
import { editAccess, formatDate } from "../utils/utils.js";
import solenoid_image from "../../assets/images/solenoid_image.png";
import axios from "axios";
import { BackIcon } from "../../assets/icons";

// Styled Components
const Container = styled.div`
  margin: auto;
  width: 90%;
  @media (min-width: 61.313em) {
    width: 80%;
  }
`;

const BackIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    border: 2px solid var(--primary);
  }
  @media (min-width: 61.313em) {
    width: 55px;
    height: 55px;
  }
`;

const SolenoidBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  &:hover {
    border: 2px solid var(--primary);
  }

  img {
    margin-right: 1rem;
    width: 20px;
    height: 20px;
  }
`;

const TimeElapsed = styled.p`
  font-size: 0.7rem;
  font-weight: bold;
  text-align: end;
  display: flex;
  align-items: center;
  @media (min-width: 61.313em) {
    text-align: end;
  }
`;

const SolenoidDetailPage = () => {
  const history = useHistory();
  const state = useContext(GlobalState);
  const { solenoid_id, location_name } = useParams();
  const [solenoidData, setSolenoidData] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const fetchSolenoidDetails = async () => {
      if (!solenoid_id) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/sensor/solenoid?solenoid_id=${solenoid_id}`,
          {
            headers: { Authorization: state.token[0] },
          }
        );

        setSolenoidData(response.data);
        console.log("Fetched solenoid data:", response.data);
      } catch (error) {
        console.error("Error fetching solenoid details:", error);
      } finally {
        setLoading(false); // Stop loading after fetching
      }
    };

    fetchSolenoidDetails();
  }, [solenoid_id, state.token]);

  // Debugging log
  console.log("motor_id before rendering:", solenoidData?.data?.motor_id);

  return (
    <div>
      <Container>
        <div style={{ display: "flex" }}>
          <BackIconWrapper onClick={() => history.push("/")}>
            <BackIcon />
          </BackIconWrapper>

          <h1 style={{ marginTop: "1rem", marginLeft: "1rem", fontSize: "1.5rem" }}>
            Motor Mapping
          </h1>
        </div>

        <div style={{ justifyItems: "center" }}>
          {/* Show loading state while fetching data */}
          {loading ? (
            <p>Loading solenoid details...</p>
          ) : (
            <>
              {/* Sensor Box */}
              <DashboadSensorBoxV2
                sensor_id={solenoidData?.data?.sensor_id}
                type="water-level"
              />

              {/* Vertical Divider */}
              <div
                style={{
                  width: "2px",
                  height: "60px",
                  backgroundColor: "#000000",
                  margin: "0.5rem auto",
                }}
              ></div>

              {/* Solenoid Box */}
              <SolenoidBox>
                <div>
                  <img src={solenoid_image} alt="Solenoid" />
                  <span style={{ fontSize: "0.9rem" }}>{location_name}</span>
                </div>

                <div style={{ display: "flex", alignItems: "flex-start", marginTop: "0.5rem" }}>
                  {solenoidData?.data?.sensor_online ? (
                    <RiSignalWifi3Fill size={20} color={"green"} />
                  ) : (
                    <Tooltip content={<>Solenoid is offline.</>} direction="right" delay="0">
                      <RiSignalWifiOffLine size={20} color={"red"} />
                    </Tooltip>
                  )}

                  {solenoidData?.data?.updatedAt && (
                    <TimeElapsed>
                      <MdUpdate /> &bull; &nbsp;
                      {formatDate(solenoidData.data.updatedAt)}
                    </TimeElapsed>
                  )}
                </div>
              </SolenoidBox>

              {/* Vertical Divider */}
              <div
                style={{
                  width: "2px",
                  height: "60px",
                  backgroundColor: "#000000",
                  margin: "0.5rem auto",
                }}
              ></div>

              {/* PumpStatusV2 - Render only when motor_id is available */}
              {solenoidData?.data?.motor_id ? (
                <PumpStatusV2 key={solenoidData.data.motor_id} motor_id={solenoidData.data.motor_id} type="motor" />
              ) : (
                <p>No motor assigned to this solenoid.</p>
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default SolenoidDetailPage;
